import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakti"} />
		<Helmet>
			<title>
				ExpertPathway
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:title"} content={"ExpertPathway"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				ExpertPathway
			</Override>
		</Components.Header>
		<Section background="--color-darkL2" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Контактна інформація
				</Text>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Маєте запитання або хочете записатися на ремонт?{" "}
					<br />
					Зв’яжіться з нами:
				</Text>
				<Link
					href="mailto:blank?info@24autoventurecart.com"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@expertpathway24.live.com
				</Link>
				<Link
					href="tel:0667508275"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					0667508275
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					проспект Михайла Грушевського, 94,
					<br />
					{" "}Коломия, Івано-Франківська область, 78200
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link5">
				0667508275
			</Override>
			<Override slot="link6">
				info@expertpathway24.live.com
			</Override>
			<Override slot="text2">
				проспект Михайла Грушевського, 94,
				<br />
				{" "}Коломия, Івано-Франківська область, 78200
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});